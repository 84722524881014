<template>
    <div class="ytm-container" style="margin-top: 1rem">
        <p style="font-size: 2.5rem; font-weight: 600; margin-bottom: 1rem">
            Админка YouTeachMe
        </p>
        <p style="margin-bottom: 0.5rem">Разделы:</p>
        <p class="ytm-admin-menu-item" @click="$router.push('/admin/teacher_verification')">
            Верификация профилей преподавателей
        </p>
        <p class="ytm-admin-menu-item" @click="$router.push('/admin/mailing')">
            Отправка рассылок
        </p>
    </div>
</template>

<script>
import "@/styles/style.css";

export default {
    name: 'AdminPage',
};
</script>

<style scoped>
.ytm-admin-menu-item {
    margin-left: 1rem;
    cursor: pointer;
}
</style>